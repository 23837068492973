'use strict';

import gameLogic from './logic/gameLogic';
import cardView from '../views/cardView';
import gameModel from './models/gameModel';
import actionView from '../views/actionView';
import answerView from '../views/answerView';
import dealView from '../views/dealView';
import streakView from '../views/streakView';
import missedView from '../views/missedView';
import strategyCardView from '../views/strategyCardView';
import nextView from '../views/nextView';
import metaImage from '../images/stand-on-15-high-resolution-logo-twitter.png';

const controlStart = function (reDeal = false, aces = false, pairs = false) {
  gameLogic.Deal(reDeal, aces, pairs);
  cardView.showPlayerCards(gameModel.state);
  gameLogic.GetRecommendation();
};

const controlAction = function (action) {
  cardView.flipDealerCard(gameModel.state);
  gameLogic.CompareRecommendation(action);
  answerView.render(gameModel.state);
  streakView.render(gameModel.state);
  missedView.render(gameModel.state);
};

const controlNext = function () {
  answerView.clear();
  actionView.changeButtonsBack();
  gameLogic.DealNext();
  cardView.showPlayerCards(gameModel.state);
  gameLogic.GetRecommendation();
};

const controlRestart = function () {
  answerView.clear();
  missedView.clear();
  actionView.changeButtonsBack();
  controlStart(true);
};

const controlAces = function () {
  answerView.clear();
  missedView.clear();
  actionView.changeButtonsBack();
  controlStart(false, true);
};

const controlPairs = function () {
  answerView.clear();
  missedView.clear();
  actionView.changeButtonsBack();
  controlStart(false, false, true);
};

const init = function () {
  actionView.addHandlerRender(controlAction);
  dealView.addHandlerRestart(controlRestart);
  dealView.addHandlerAces(controlAces);
  dealView.addHandlerPairs(controlPairs);
  strategyCardView.addHandlerShowPage();
  strategyCardView.addHandlerHidePage();
  nextView.addHandler(controlNext);
  controlStart();
};

init();
