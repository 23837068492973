// Import each card image

import cardBack from '../images/cardDeck/card_back.svg';
// Hearts
import aceOfHearts from '../images/cardDeck/ace_of_hearts.svg';
import twoOfHearts from '../images/cardDeck/2_of_hearts.svg';
import threeOfHearts from '../images/cardDeck/3_of_hearts.svg';
import fourOfHearts from '../images/cardDeck/4_of_hearts.svg';
import fiveOfHearts from '../images/cardDeck/5_of_hearts.svg';
import sixOfHearts from '../images/cardDeck/6_of_hearts.svg';
import sevenOfHearts from '../images/cardDeck/7_of_hearts.svg';
import eightOfHearts from '../images/cardDeck/8_of_hearts.svg';
import nineOfHearts from '../images/cardDeck/9_of_hearts.svg';
import tenOfHearts from '../images/cardDeck/10_of_hearts.svg';
import jackOfHearts from '../images/cardDeck/jack_of_hearts.svg';
import queenOfHearts from '../images/cardDeck/queen_of_hearts.svg';
import kingOfHearts from '../images/cardDeck/king_of_hearts.svg';

// Diamonds
// Diamonds
import aceOfDiamonds from '../images/cardDeck/ace_of_diamonds.svg';
import twoOfDiamonds from '../images/cardDeck/2_of_diamonds.svg';
import threeOfDiamonds from '../images/cardDeck/3_of_diamonds.svg';
import fourOfDiamonds from '../images/cardDeck/4_of_diamonds.svg';
import fiveOfDiamonds from '../images/cardDeck/5_of_diamonds.svg';
import sixOfDiamonds from '../images/cardDeck/6_of_diamonds.svg';
import sevenOfDiamonds from '../images/cardDeck/7_of_diamonds.svg';
import eightOfDiamonds from '../images/cardDeck/8_of_diamonds.svg';
import nineOfDiamonds from '../images/cardDeck/9_of_diamonds.svg';
import tenOfDiamonds from '../images/cardDeck/10_of_diamonds.svg';
import jackOfDiamonds from '../images/cardDeck/jack_of_diamonds.svg';
import queenOfDiamonds from '../images/cardDeck/queen_of_diamonds.svg';
import kingOfDiamonds from '../images/cardDeck/king_of_diamonds.svg';

// ... continue importing Diamonds (2 through King)

// Clubs

import aceOfClubs from '../images/cardDeck/ace_of_clubs.svg';
import twoOfClubs from '../images/cardDeck/2_of_clubs.svg';
import threeOfClubs from '../images/cardDeck/3_of_clubs.svg';
import fourOfClubs from '../images/cardDeck/4_of_clubs.svg';
import fiveOfClubs from '../images/cardDeck/5_of_clubs.svg';
import sixOfClubs from '../images/cardDeck/6_of_clubs.svg';
import sevenOfClubs from '../images/cardDeck/7_of_clubs.svg';
import eightOfClubs from '../images/cardDeck/8_of_clubs.svg';
import nineOfClubs from '../images/cardDeck/9_of_clubs.svg';
import tenOfClubs from '../images/cardDeck/10_of_clubs.svg';
import jackOfClubs from '../images/cardDeck/jack_of_clubs.svg';
import queenOfClubs from '../images/cardDeck/queen_of_clubs.svg';
import kingOfClubs from '../images/cardDeck/king_of_clubs.svg';
// ... continue importing Clubs (2 through King)

// Spades
import aceOfSpades from '../images/cardDeck/ace_of_spades.svg';
import twoOfSpades from '../images/cardDeck/2_of_spades.svg';
import threeOfSpades from '../images/cardDeck/3_of_spades.svg';
import fourOfSpades from '../images/cardDeck/4_of_spades.svg';
import fiveOfSpades from '../images/cardDeck/5_of_spades.svg';
import sixOfSpades from '../images/cardDeck/6_of_spades.svg';
import sevenOfSpades from '../images/cardDeck/7_of_spades.svg';
import eightOfSpades from '../images/cardDeck/8_of_spades.svg';
import nineOfSpades from '../images/cardDeck/9_of_spades.svg';
import tenOfSpades from '../images/cardDeck/10_of_spades.svg';
import jackOfSpades from '../images/cardDeck/jack_of_spades.svg';
import queenOfSpades from '../images/cardDeck/queen_of_spades.svg';
import kingOfSpades from '../images/cardDeck/king_of_spades.svg';
// ... continue importing Spades (2 through King)

// Export an object with card names as keys and imported images as values
export const cardsImages = {
  card_back: cardBack,
  // Hearts
  ace_of_hearts: aceOfHearts,
  '2_of_hearts': twoOfHearts,
  '3_of_hearts': threeOfHearts,
  '4_of_hearts': fourOfHearts,
  '5_of_hearts': fiveOfHearts,
  '6_of_hearts': sixOfHearts,
  '7_of_hearts': sevenOfHearts,
  '8_of_hearts': eightOfHearts,
  '9_of_hearts': nineOfHearts,
  '10_of_hearts': tenOfHearts,
  jack_of_hearts: jackOfHearts,
  queen_of_hearts: queenOfHearts,
  king_of_hearts: kingOfHearts,
  // ... continue for Hearts (4 through King)

  // Diamonds
  ace_of_diamonds: aceOfDiamonds,
  '2_of_diamonds': twoOfDiamonds,
  '3_of_diamonds': threeOfDiamonds,
  '4_of_diamonds': fourOfDiamonds,
  '5_of_diamonds': fiveOfDiamonds,
  '6_of_diamonds': sixOfDiamonds,
  '7_of_diamonds': sevenOfDiamonds,
  '8_of_diamonds': eightOfDiamonds,
  '9_of_diamonds': nineOfDiamonds,
  '10_of_diamonds': tenOfDiamonds,
  jack_of_diamonds: jackOfDiamonds,
  queen_of_diamonds: queenOfDiamonds,
  king_of_diamonds: kingOfDiamonds,
  // ... continue for Diamonds (2 through King)

  // Clubs
  ace_of_clubs: aceOfClubs,
  '2_of_clubs': twoOfClubs,
  '3_of_clubs': threeOfClubs,
  '4_of_clubs': fourOfClubs,
  '5_of_clubs': fiveOfClubs,
  '6_of_clubs': sixOfClubs,
  '7_of_clubs': sevenOfClubs,
  '8_of_clubs': eightOfClubs,
  '9_of_clubs': nineOfClubs,
  '10_of_clubs': tenOfClubs,
  jack_of_clubs: jackOfClubs,
  queen_of_clubs: queenOfClubs,
  king_of_clubs: kingOfClubs,
  // ... continue for Clubs (2 through King)

  // Spades
  ace_of_spades: aceOfSpades,
  '2_of_spades': twoOfSpades,
  '3_of_spades': threeOfSpades,
  '4_of_spades': fourOfSpades,
  '5_of_spades': fiveOfSpades,
  '6_of_spades': sixOfSpades,
  '7_of_spades': sevenOfSpades,
  '8_of_spades': eightOfSpades,
  '9_of_spades': nineOfSpades,
  '10_of_spades': tenOfSpades,
  jack_of_spades: jackOfSpades,
  queen_of_spades: queenOfSpades,
  king_of_spades: kingOfSpades,
  // ... continue for Spades (2 through King)
};

// Continue this pattern for the rest of the deck:
// - 2, 3, 4, 5, 6, 7, 8, 9, 10, Jack, Queen, King for Diamonds, Clubs, Spades
