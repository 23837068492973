class GameModel {
  state = {
    recommendation: '',
    answer: '',
    details: '',
    streak: 0,
    missed: [],
    allCards: [],
    allAces: [],
    aces: false,
    pairs: false,
    dealer: {
      score: [],
      cards: [],
    },
    user: {
      score: [],
      cards: [],
    },
  };
}

export default new GameModel();
