import { CardModel } from './cardModel';

export class DeckModel {
  _suits = ['hearts', 'diamonds', 'clubs', 'spades'];
  _values = [
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    'jack',
    'queen',
    'king',
    'ace',
  ];

  fullDeck = [];

  constructor() {
    this._InitializeDeck();
    this._ShuffleDeck();
  }

  _InitializeDeck() {
    for (let suit of this._suits) {
      for (let value of this._values) {
        this.fullDeck.push(new CardModel(suit, value));
      }
    }
  }

  _ShuffleDeck() {
    for (let i = this.fullDeck.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [this.fullDeck[i], this.fullDeck[j]] = [
        this.fullDeck[j],
        this.fullDeck[i],
      ];
    }
  }
}
