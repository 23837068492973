class StrategyCardView {
  _cardPage = document.querySelector('.strategy-card-page');
  _cardIcon = document.querySelector('.strategy-card-container');

  _body = document.querySelector('body');
  _header = document.querySelector('header');

  _pageTimeout = '';

  _settingHidePage = function () {
    this._pageTimeout = setTimeout(() => {
      this._cardPage.classList.add('hidden');
    }, 5000);
  };

  addHandlerShowPage() {
    this._cardIcon.addEventListener('click', (e) => {
      e.preventDefault();

      clearTimeout(this._pageTimeout);

      this._cardPage.classList.remove('hidden');

      this._cardPage.classList.remove('hide-page');
      this._cardPage.classList.add('show-page');
      this._body.classList.add('body-overflow-on');
    });
  }

  addHandlerHidePage() {
    this._header.addEventListener('click', () => {
      this._cardPage.classList.remove('show-page');
      this._cardPage.classList.add('hide-page');
      this._body.classList.remove('body-overflow-on');

      this._settingHidePage();
    });
  }
}

export default new StrategyCardView();
