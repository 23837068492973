class DealView {
  _dealContainer = document.querySelector('.deal-container');

  addHandlerRestart(handler) {
    this._dealContainer.addEventListener('click', (e) => {
      this._addClickedClass(e, 'btn--restart', handler);
    });
  }

  _addClickedClass(e, buttonType, handler) {
    if (!e.target.classList.contains(buttonType)) return;

    const btn = e.target;

    this._removeClicked();

    btn.classList.add('btn--clicked');

    handler();
  }

  _removeClicked() {
    const allDealButtons = this._dealContainer.querySelectorAll('.btn');

    allDealButtons.forEach((btn) => btn.classList.remove('btn--clicked'));
  }

  addHandlerAces(handler) {
    this._dealContainer.addEventListener('click', (e) => {
      this._addClickedClass(e, 'btn--aces', handler);
    });
  }

  addHandlerPairs(handler) {
    this._dealContainer.addEventListener('click', (e) => {
      this._addClickedClass(e, 'btn--pairs', handler);
    });
  }
}

export default new DealView();
