class NextView {
  _nextBtn = document.querySelector('.btn--next');


  addHandler(handler) {
    this._nextBtn.addEventListener('click', handler);
  }
}

export default new NextView();
