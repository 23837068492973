class MissedView {
  _missedList = document.querySelector('.missed-container ul');

  render(state) {
    this.clear();
    const html = state.missed
      .map((cards, i, array) => {
        if (i < 5) {
          return `<li>${cards[0].value} + ${cards[1].value}</li>`;
        } else if (i === 5) {
          return `<li>...${array.length - 5} more</li>`;
        }
      })
      .join('');

    this._missedList.insertAdjacentHTML('beforeend', html);
  }

  clear() {
    this._missedList.innerHTML = '';
  }
}

export default new MissedView();
