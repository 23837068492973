'use strict';

import { DeckModel } from '../models/deckModel';
import gameModel from '../models/gameModel';

class GameLogic {
  Deal(reDeal = false, aces = false, pairs = false) {
    console.log(reDeal, aces, pairs);
    console.log(gameModel.state);

    if (gameModel.state.allCards.length < 5 || reDeal) {
      const allCards = this.GetDecks();
      gameModel.state.allCards = allCards;
    }

    if (reDeal) {
      gameModel.state.streak = 0;
      gameModel.state.missed = [];
      gameModel.state.aces = false;
      gameModel.state.pairs = false;
    }

    if (aces) {
      gameModel.state.aces = true;
      gameModel.state.pairs = false;
      this._DealAces();
      return;
    }

    if (pairs) {
      gameModel.state.pairs = true;
      gameModel.state.aces = false;
      this._DealPairs();
      return;
    }

    this.DealNext();
  }

  DealNext() {
    if (gameModel.state.aces) {
      this._DealAces();
      return;
    }

    if (gameModel.state.pairs) {
      this._DealPairs();
      return;
    }
    if (gameModel.state.allCards.length < 5) {
      const allCards = this.GetDecks();
      gameModel.state.allCards = allCards;
    }

    const delt = [];

    for (let i = 0; i < 4; i++) {
      delt.push(gameModel.state.allCards.shift());
    }

    const dealerDownCard = delt[1];
    const dealerUpCard = delt[3];
    const userFirstCard = delt[0];
    const userSecondCard = delt[2];

    gameModel.state.dealer.cards = [dealerDownCard, dealerUpCard];
    gameModel.state.dealer.score = [
      +dealerDownCard.value + +dealerUpCard.value,
    ];

    gameModel.state.user.cards = [userFirstCard, userSecondCard];

    this.CalculateScores();
  }

  _DealPairs() {
    const delt = [];

    for (let i = 0; i < 3; i++) {
      delt.push(gameModel.state.allCards.shift());
    }

    const dealerDownCard = delt[1];
    const dealerUpCard = delt[2];
    const userFirstCard = delt[0];
    let userSecondCard = gameModel.state.allCards.find(
      (card) => card.value === userFirstCard.value
    );

    if (!userSecondCard) {
      const allCards = this.GetDecks();
      gameModel.state.allCards = allCards;
      userSecondCard = gameModel.state.allCards.find(
        (card) => card.value === userFirstCard.value
      );
    }

    gameModel.state.dealer.cards = [dealerDownCard, dealerUpCard];
    gameModel.state.dealer.score = [
      +dealerDownCard.value + +dealerUpCard.value,
    ];

    gameModel.state.user.cards = [userFirstCard, userSecondCard];

    this.CalculateScores();
  }

  _DealAces() {
    const allCards = this.GetDecks();
    gameModel.state.allCards = allCards;

    if (gameModel.state.allAces.length < 2) {
      gameModel.state.allAces = gameModel.state.allCards.filter((card) => {
        if (card.value === 'ace') {
          return card;
        }
      });
    }

    const delt = [];

    for (let i = 0; i < 3; i++) {
      delt.push(gameModel.state.allCards.shift());
    }

    const randomIndex = Math.trunc(Math.random() * 15 + 1);

    const dealerDownCard = delt[1];
    const dealerUpCard = delt[2];
    const userFirstCard =
      randomIndex % 2 != 0 ? gameModel.state.allAces[randomIndex] : delt[0];
    const userSecondCard =
      randomIndex % 2 === 0 ? gameModel.state.allAces[randomIndex] : delt[0];

    gameModel.state.dealer.cards = [dealerDownCard, dealerUpCard];
    gameModel.state.dealer.score = [
      +dealerDownCard.value + +dealerUpCard.value,
    ];

    gameModel.state.user.cards = [userFirstCard, userSecondCard];

    this.CalculateScores();
  }

  _AddScores(acc, card) {
    if (
      card.value === 'jack' ||
      card.value === 'queen' ||
      card.value === 'king'
    ) {
      return acc + 10;
    }
    if (card.value === 'ace') return acc + 1;
    return acc + +card.value;
  }

  CalculateScores() {
    gameModel.state.user.score = gameModel.state.user.cards.reduce(
      this._AddScores,
      0
    );

    gameModel.state.dealer.score = gameModel.state.dealer.cards.reduce(
      this._AddScores,
      0
    );
  }

  _HandleAces(numberCard, showCard) {
    switch (numberCard) {
      case 2:
      case 3:
        gameModel.state.details = 'Double-Down if 5 - 6 else Hit';

        if (showCard >= 5 && showCard <= 6) {
          gameModel.state.recommendation = 'Double';
          return;
        } else {
          gameModel.state.recommendation = 'Hit';
          return;
        }
        break;
      case 4:
      case 5:
        gameModel.state.details = 'Double-Down if 4 - 6 else Hit';

        if (showCard >= 4 && showCard <= 6) {
          gameModel.state.recommendation = 'Double';
          return;
        } else {
          gameModel.state.recommendation = 'Hit';
          return;
        }
        break;
      case 6:
        gameModel.state.details = 'Double-Down if 3 - 6 else Hit';

        if (showCard >= 3 && showCard <= 6) {
          gameModel.state.recommendation = 'Double';
          return;
        } else {
          gameModel.state.recommendation = 'Hit';
          return;
        }
        break;
      case 7:
        gameModel.state.details =
          'Double-Down if 2 - 6, Stand if 7 - 8, else Hit';

        if (showCard >= 2 && showCard <= 6) {
          gameModel.state.recommendation = 'Double';
          return;
        }
        if (showCard >= 7 && showCard <= 8) {
          gameModel.state.recommendation = 'Stand';
          return;
        }
        gameModel.state.recommendation = 'Hit';
        return;
        break;
      case 8:
        gameModel.state.details = 'Double-Down if 6 else Stand';

        if (showCard === 6) {
          gameModel.state.recommendation = 'Double';
          return;
        } else {
          gameModel.state.recommendation = 'Stand';
          return;
        }
        break;
      case 9:
        gameModel.state.details = 'Always Stand';

        gameModel.state.recommendation = 'Stand';
        return;
        break;
      default:
        gameModel.state.recommendation = 'Blackjack!';

        return;
        break;
    }
  }

  _HandlePairs(cardValue, showCard) {
    switch (cardValue) {
      case 1:
        gameModel.state.details = "Always Split Ace's and 8's";
        gameModel.state.recommendation = 'Split';
        return;
        break;
      case 2:
      case 3:
        gameModel.state.details = 'Split if 2 - 7 else Hit';

        if (showCard >= 2 && showCard <= 7) {
          gameModel.state.recommendation = 'Split';
          return;
        } else {
          gameModel.state.recommendation = 'Hit';
          return;
        }

        break;
      case 4:
        gameModel.state.details = 'Split if 5 - 6 else Hit';

        if (showCard === 5 || showCard === 6) {
          gameModel.state.recommendation = 'Split';
          return;
        } else {
          gameModel.state.recommendation = 'Hit';
          return;
        }
        break;
      case 5:
        gameModel.state.details = 'Double-Down if 2 - 9 else Hit';
        if (showCard >= 2 && showCard <= 9) {
          gameModel.state.recommendation = 'Double';
          return;
        } else {
          gameModel.state.recommendation = 'Hit';
          return;
        }
        break;
      case 6:
        gameModel.state.details = 'Split if 2 - 6 else Hit';
        if (showCard >= 2 && showCard <= 6) {
          gameModel.state.recommendation = 'Split';
          return;
        } else {
          gameModel.state.recommendation = 'Hit';
          return;
        }
        break;
      case 7:
        gameModel.state.details = 'Split if 2 - 7 else Hit';
        if (showCard >= 2 && showCard <= 7) {
          gameModel.state.recommendation = 'Split';
          return;
        } else {
          gameModel.state.recommendation = 'Hit';
          return;
        }
        break;
      case 8:
        gameModel.state.details = "Always Split Ace's and 8's";
        gameModel.state.recommendation = 'Split';
        return;
        break;
      case 9:
        gameModel.state.details = 'Split if 2 - 6 and 8 - 9, else Stand';
        if (
          (showCard >= 2 && showCard <= 6) ||
          (showCard >= 8 && showCard <= 9)
        ) {
          gameModel.state.recommendation = 'Split';
          return;
        } else {
          gameModel.state.recommendation = 'Stand';
          return;
        }
        break;
      case 10:
        gameModel.state.details = 'Always Stand';
        gameModel.state.recommendation = 'Stand';
        return;
        break;

      default:
        break;
    }
  }

  GetRecommendation() {
    const fixCardValue = function (userCard) {
      const isNumber = parseInt(userCard);
      if (isNaN(isNumber) && userCard === 'ace') {
        return 1;
      } else if (isNaN(isNumber)) {
        return 10;
      } else {
        return +isNumber;
      }
    };

    let userCard1Value = fixCardValue(gameModel.state.user.cards[0].value);
    let userCard2Value = fixCardValue(gameModel.state.user.cards[1].value);
    let showCard = fixCardValue(gameModel.state.dealer.cards[1].value);

    const userTotal = gameModel.state.user.score;
    const userCards = gameModel.state.user.cards[0];

    if (userCard1Value === userCard2Value) {
      this._HandlePairs(userCard1Value, showCard);

      return;
    }

    if (userCard1Value === 1 || userCard2Value === 1) {
      const numberCard = userCard1Value === 1 ? userCard2Value : userCard1Value;

      this._HandleAces(numberCard, showCard);

      return;
    }

    switch (userTotal) {
      case 5:
      case 6:
      case 7:
      case 8:
        gameModel.state.recommendation = 'Hit';
        gameModel.state.details = 'On 5, 6, 7, 8 always Hit';
        return;
        break;
      case 9:
        gameModel.state.details = 'Double-Down if 3 - 6 else Hit';

        if (showCard >= 3 && showCard <= 6) {
          gameModel.state.recommendation = 'Double';

          return;
        } else {
          gameModel.state.recommendation = 'Hit';
          return;
        }
        break;
      case 10:
        gameModel.state.details = 'Double-Down if 2 - 9 else Hit';

        if (showCard >= 2 && showCard <= 9) {
          gameModel.state.recommendation = 'Double';
          return;
        } else {
          gameModel.state.recommendation = 'Hit';
          return;
        }
        break;
      case 11:
        gameModel.state.details = 'Always Double-Down';

        gameModel.state.recommendation = 'Double';
        return;
        break;
      case 12:
        gameModel.state.details = 'Stand if 4 - 6 else Hit';

        if (
          (showCard >= 1 && showCard <= 3) ||
          (showCard >= 7 && showCard <= 10)
        ) {
          gameModel.state.recommendation = 'Hit';
          return;
        } else {
          gameModel.state.recommendation = 'Stand';
          return;
        }
        break;
      case 13:
      case 14:
      case 15:
      case 16:
        gameModel.state.details = 'Stand if 2 - 6 else Hit';

        if ((showCard >= 7 && showCard <= 10) || showCard === 1) {
          gameModel.state.recommendation = 'Hit';
          return;
        } else {
          gameModel.state.recommendation = 'Stand';
          return;
        }
        break;
      case 17:
      case 18:
      case 19:
        gameModel.state.details = 'On 17 - 20 always Stand';

        gameModel.state.recommendation = 'Stand';
        return;
        break;

      default:
        break;
    }
  }

  CompareRecommendation(rec) {
    const modelRec = gameModel.state.recommendation;

    if (modelRec === 'Blackjack!') {
      gameModel.state.answer = modelRec;
      gameModel.state.details = 'Nothing to do here...';
      return;
    }

    if (rec === modelRec) {
      gameModel.state.answer = 'Correct';
      gameModel.state.streak += 1;
    } else {
      gameModel.state.answer = 'Incorrect';
      gameModel.state.streak = 0;
      gameModel.state.missed.push(gameModel.state.user.cards);
    }
  }

  GetDecks() {
    gameModel.state.numberOfDecks = 4;

    const decks = [];
    for (let i = 0; i < 4; i++) {
      const deck = new DeckModel();
      decks.push(...deck.fullDeck);
    }

    return decks;
  }
}

export default new GameLogic();
