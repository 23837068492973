// import icons from 'url:../../img/icons.svg'; // Parcel 2

import { cardsImages } from '../js/cardImages';

class CardView {
  _dealerContainer = document.querySelector('.card-container--dealer');
  _userContainer = document.querySelector('.card-container--user');

  showPlayerCards(state) {
    this._clear();
    const dealerCardUp = state.dealer.cards[1];
    const dealerCardDownImage = cardsImages['card_back'];
    const dealerCardUpImage =
      cardsImages[`${dealerCardUp.value}_of_${dealerCardUp.suit}`];

    const dealerHTML = `<img class="playing-card playing-card--down-card" alt="the bakc of a playing card" src="${dealerCardDownImage}" /> <img class="playing-card playing-card--up-card" alt="a playing card of ${dealerCardUp.value} of ${dealerCardUp.suit}" src="${dealerCardUpImage}" />`;

    this._dealerContainer.insertAdjacentHTML('afterbegin', dealerHTML);

    let userHtml;

    const userCards = state.user.cards;

    userCards.forEach((card, cardNumber) => {
      const cardImage = cardsImages[`${card.value}_of_${card.suit}`];
      userHtml = `<img class="playing-card playing-card--user-${
        cardNumber + 1
      }" alt="a playing card of ${card.value} of ${
        card.suit
      }" src="${cardImage}" />`;

      document;
      this._userContainer.insertAdjacentHTML('afterbegin', userHtml);
    });
  }

  flipDealerCard(state) {
    const dealerDownCard = this._dealerContainer.querySelector(
      '.playing-card--down-card'
    );
    dealerDownCard.src =
      cardsImages[
        `${state.dealer.cards[0].value}_of_${state.dealer.cards[0].suit}`
      ];
  }

  _clear() {
    this._dealerContainer.innerHTML = '';
    this._userContainer.innerHTML = '';
  }
}

export default new CardView();
