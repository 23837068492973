class ActionView {
  _mainContainer = document.querySelector('.button-container');

  addHandlerRender(handler) {
    this._mainContainer.addEventListener('click', (e) => {
      if (!e.target.classList.contains('btn')) return;

      const btn = e.target;

      btn.classList.add('btn--clicked');

      const action = btn.dataset.action;

      handler(action);
    });
  }

  changeButtonsBack() {
    const btns = this._mainContainer.querySelectorAll('.btn');

    btns.forEach((btn) => {
      btn.classList.remove('btn--clicked');
    });
  }
}

export default new ActionView();
