class AnswerView {
  _answer = document.querySelector('.answer');
  _slogan = document.querySelector('.slogan');
  _nextButton = document.querySelector('.btn--next');

  render(state) {
    this._answer.textContent = `${state.answer}!`;

    if (state.answer === 'Incorrect') {
      this._answer.style.color = '#92b19f';
    } else {
      this._answer.style.color = '#d09632';
    }

    this._slogan.textContent = `"${state.details}"`;
  }

  clear() {
    this._answer.textContent = '';
    this._slogan.textContent = '';
  }
}

export default new AnswerView();
